export const ROUTES = {
  ADMIN_PANEL: {
    COMMENTS_PAGE: '/admin-comments',
    USERS_ADMIN_PANEL: '/users',
    ENTITIES_ADMIN_PANEL: '/entities',
    CREATE_EDIT_USER_ADMIN_PANEL: '/user/:id?',
    CREATE_EDIT_ENTITY_ADMIN_PANEL: '/entity/:id?',
    TAGS: '/tags',
    PHONETICS: '/phonetics',
    PREMUIM_INFO: '/premium',
    USER_PHOTOS: '/photos',
    BILLING: '/billings',
    PLAN: '/plan',
    ENTITY_EVENTS_ADMIN_PANEL: '/entities-events',
    PLANS_ADMIN_PANEL: '/plans-list',
    PACKS_ADMIN_PANEL: '/packs-list',
    GENERAL_STATISTICS: '/statistics',
    EVENTS_STATISTICS: '/events',
    SEARCH_STATISTICS: '/search',
    UPLOAD_CSV: '/upload-csv',
    ZONE_STATS: '/zone-stats',
  },
  AUTH: {
    LOGIN_PAGE: '/sign-in',
    REGISTRATION_PAGE: '/sign-up',
    CHANGE_PASSWORD_PAGE: '/change-password',
    FORGET_PASSWORD_PAGE: '/forget-password',
    RECOVER_PASSWORD: '/recover-password',
    CONFIRM_BY_EMAIL: '/confirm-by-email',
  },
  BASE: {
    EVENTS: '/',
    WIDGET_EVENTS: '/contributor-event/:contributorId',
    SINGLE_EVENT: '/event/:id/:lang',
    CONTRIBUTOR_PAGE: '/@:alias',
    CONTRIBUTOR_PAGE_ID: '/contributor/:id',
    PRICING_PLAN_PAGE: '/pricing-plan',
    ERROR_PAGE: '*',
  },
  EVENTS: {
    REGIONS: '/regions/:region/:category?',
    VILLES: '/villes/:city/:category?',
  },
  PROFILE: {
    EMAIL_ACTIVATE: '/email-activate',
    AGENT_ACCOUNT_SETTINGS_PAGE: '/settings',
    MY_ENTITY: '/my-entity',
    AGENT_IMAGE_EDIT_PAGE: '/change-image',
    ACCOUNT_SECURITY: '/security',
    FAVORITES: '/favorites',
    INVOICES: '/invoices',
    PLANS: '/plans',
    PAST_EVENTS: '/past-events',
    SHAREABLE_EVENTS: '/shareable-events',
    CREATE_EVENT: '/create-event',
    EDIT_EVENT: '/edit-event/:id?',
    COMMENTS: '/comments',
    REPORTS: '/reports',
    WIDGET: '/widget',
    MY_EVENTS: '/events',
    MY_PLAN: '/my-subscription',
  },
};
