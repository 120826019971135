import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import i18n from 'i18n';
import { getUserInfo, handleError, showSuccess, updateUserInfo } from 'utils/helpers';
import { IUser } from 'interfaces';
import * as ActionTypes from './Profile.actions';
import * as Interfaces from './Profile.interfaces';
import * as AdminActionTypes from '../AdminPanel/AdminPanel.actions';
import Api from './Profile.api';

function* getUsersEvents({ payload }: Interfaces.GetUsersEventsAction) {
  try {
    const { data } = yield call(Api.getUsersEvents, payload);
    yield put(ActionTypes.getUsersEvents.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getUsersEvents.failure(e));
  }
}

function* getUsersEventsSaga() {
  yield takeLatest(ActionTypes.GET_USERS_EVENTS, getUsersEvents);
}

function* toggleShareEvent({ payload }: Interfaces.ToggleShareEventAction) {
  try {
    const { data } = yield call(Api.toggleShareEvent, payload.id, payload.share);
    yield put(ActionTypes.toggleShareEvent.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.toggleShareEvent.failure(e));
  }
}

function* toggleShareEventSaga() {
  yield takeLatest(ActionTypes.TOGGLE_SHARE, toggleShareEvent);
}

function* getTags({ payload }: Interfaces.GetTagsAction) {
  try {
    const { data } = yield call(Api.getTags, payload);
    yield put(ActionTypes.getTags.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getTags.failure(e));
  }
}

function* getTagsSaga() {
  yield takeLatest(ActionTypes.GET_TAGS, getTags);
}

function* getUsersById({ payload }: Interfaces.GetUsersByIdAction) {
  try {
    const { data } = yield call(Api.getUsersById, payload);
    yield put(ActionTypes.getUsersById.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getUsersById.failure(e));
  }
}

function* getUsersByIdSaga() {
  yield takeLatest(ActionTypes.GET_USERS_BY_ID, getUsersById);
}

function* putFavorite({ payload }: Interfaces.PutFavoriteAction) {
  try {
    const { data } = yield call(Api.putFavorite, payload);
    yield put(ActionTypes.putFavorite.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.putFavorite.failure(e));
  }
}

function* putFavoriteSaga() {
  yield takeLatest(ActionTypes.PUT_FAVORITE, putFavorite);
}

function* getContributors({ payload }: Interfaces.GetContributorsAction) {
  try {
    const { data } = yield call(Api.getContributors, payload.query);
    yield put(ActionTypes.getContributors.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getContributors.failure(e));
  }
}

function* getContributorsSaga() {
  yield takeLatest(ActionTypes.GET_CONTRIBUTORS, getContributors);
}

function* getProducers({ payload }: Interfaces.GetContributorsAction) {
  try {
    const { data } = yield call(Api.getProducers, payload.query);
    yield put(ActionTypes.getProducers.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getProducers.failure(e));
  }
}

function* getProducersSaga() {
  yield takeLatest(ActionTypes.GET_PRODUCERS, getProducers);
}

function* patchUpdateUserInfo({ payload }: Interfaces.PatchUpdateUserInfoAction) {
  try {
    const { data } = yield call(Api.patchUpdateUserInfo, payload);
    updateUserInfo(data);
    yield put(ActionTypes.patchUpdateUserInfo.success(data));
    showSuccess({ text: i18n.t('Basic Information updated successfully') });
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.patchUpdateUserInfo.failure(e));
  }
}

function* patchUpdateUserInfoSaga() {
  yield takeLatest(ActionTypes.PATCH_UPDATE_USERINFO, patchUpdateUserInfo);
}

function* patchUpdateAdminInfo({ payload }: Interfaces.PatchUpdateAdminInfoAction) {
  try {
    const { data } = yield call(Api.patchUpdateAdminInfo, payload.user);
    updateUserInfo(data);
    yield put(ActionTypes.patchUpdateAdminInfo.success(data));
    showSuccess({ text: i18n.t('Basic Information updated successfully') });
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.patchUpdateAdminInfo.failure(e));
  }
}

function* patchUpdateAdminInfoSaga() {
  yield takeLatest(ActionTypes.PATCH_UPDATE_ADMIN_INFO, patchUpdateAdminInfo);
}

function* patchUpdateUserFiles({ payload }: Interfaces.PatchUpdateUserImageAction) {
  try {
    const currentUser = getUserInfo();
    const form = new FormData();
    if (payload.data.image) {
      form.append('image', payload.data.image);
    }
    if (payload.data.background_image) {
      form.append('background_image', payload.data.background_image);
    }
    const { data } = yield call(Api.patchUpdateUserFiles, { form: form, id: payload.id });
    yield put(ActionTypes.patchUpdateUserFiles.success(data));
    if (currentUser.id === payload.id) {
      Object.assign(currentUser, { avatar: data.avatar, background: data.background });
      updateUserInfo(currentUser);
    }
    showSuccess({ text: i18n.t('Image changed successfully') });
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.patchUpdateUserFiles.failure(e));
  }
}

function* patchUpdateUserImageSaga() {
  yield takeLatest(ActionTypes.PATCH_UPDATE_USER_IMAGE, patchUpdateUserFiles);
}

function* getCardHistory({ payload }: Interfaces.GetCardHistoryAction) {
  try {
    const { data } = yield call(Api.getCardHistory, payload.query);
    yield put(ActionTypes.getCardHistory.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getCardHistory.failure(e));
  }
}

function* getCardHistorySaga() {
  yield takeLatest(ActionTypes.GET_CARD_HISTORY, getCardHistory);
}

function* postCustomerPortal() {
  try {
    const { data } = yield call(Api.postCustomerPortal);
    yield put(ActionTypes.postCustomerPortal.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.postCustomerPortal.failure(e));
  }
}

function* postCustomerPortalSaga() {
  yield takeLatest(ActionTypes.POST_CUSTOMER_PORTAL, postCustomerPortal);
}

function* getOneInvoice({ payload }: Interfaces.GetOneInvoiceAction) {
  try {
    const { data } = yield call(Api.getOneInvoice, payload);
    yield put(ActionTypes.getOneInvoice.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getOneInvoice.failure(e));
  }
}

function* getOneInvoiceSaga() {
  yield takeLatest(ActionTypes.GET_ONE_INVOICE, getOneInvoice);
}

function* saveNotificationSubscription({ payload }: Interfaces.SaveNotificationSubscription) {
  try {
    const { data } = yield call(Api.saveNotificationSubscription, payload);
    yield put(ActionTypes.saveNotificationSubscription.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.saveNotificationSubscription.failure(e));
  }
}

function* saveNotificationSubscriptionSaga() {
  yield takeLatest(ActionTypes.SAVE_NOTIFICATION_SUBSCRIPTION, saveNotificationSubscription);
}

function* transcribeAudio({ payload }: Interfaces.TranscribeAudioAction) {
  try {
    const form = new FormData();
    form.append('audio_file', payload.audio_file);
    form.append('lang', payload.lang);
    form.append('type', payload.type);
    const { data } = yield call(Api.transcribeAudio, form);
    yield put(ActionTypes.transcribeAudio.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.transcribeAudio.failure(e));
  }
}

function* transcribeAudioSaga() {
  yield takeLatest(ActionTypes.TRANSCRIBE_AUDIO, transcribeAudio);
}

function* setAcceptedTerms({ payload }: Interfaces.SetAcceptedTermsAction) {
  try {
    const { data } = yield call(Api.setAcceptedTerms, payload);
    const user: IUser = getUserInfo();
    user.entity.accepted_terms_of_payment = true;
    updateUserInfo(user);
    yield put(ActionTypes.setAcceptedTerms.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.setAcceptedTerms.failure(e));
  }
}

function* setAcceptedTermsSaga() {
  yield takeLatest(ActionTypes.SET_ACCEPTED_TERMS, setAcceptedTerms);
}

function* getEntityPlan({ payload }: Interfaces.GetEntityPlanAction) {
  try {
    const { data } = yield call(Api.getEntityPlan, payload);
    yield put(ActionTypes.getEntityPlan.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getEntityPlan.failure(e));
  }
}

function* getEntityPlanSaga() {
  yield takeLatest(ActionTypes.GET_ENTITY_PLAN, getEntityPlan);
}

function* updateEntityPlan({ payload }: Interfaces.UpdateEntityPlanAction) {
  try {
    const { data } = yield call(Api.updateEntityPlan, payload);
    yield put(ActionTypes.updateEntityPlan.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.updateEntityPlan.failure(e));
  }
}

function* updateEntityPlanSaga() {
  yield takeLatest(ActionTypes.UPDATE_ENTITY_PLAN, updateEntityPlan);
}

function* getMyEntity() {
  try {
    const { data } = yield call(Api.getMyEntity);
    yield put(ActionTypes.getMyEntity.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getMyEntity.failure(e));
  }
}

function* getMyEntitySaga() {
  yield takeLatest(ActionTypes.GET_MY_ENTITY, getMyEntity);
}

function* updateEntity({ payload }: Interfaces.UpdateEntityAction) {
  try {
    const { data } = yield call(Api.updateEntity, payload);
    yield put(ActionTypes.updateEntity.success(data));
    yield put({ type: AdminActionTypes.UPDATE_ENTITY_SUCCESS, payload: data });
    showSuccess({ text: i18n.t('Entity updated successfully') });
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.updateEntity.failure(e));
  }
}

function* updateEntitySaga() {
  yield takeLatest(ActionTypes.UPDATE_ENTITY, updateEntity);
}

function* listMyFavorites() {
  try {
    const { data } = yield call(Api.listMyFavorites);
    yield put(ActionTypes.listMyFavorites.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.listMyFavorites.failure(e));
  }
}

function* listMyFavoritesSaga() {
  yield takeLatest(ActionTypes.LIST_MY_FAVORITES, listMyFavorites);
}

function* regenerateSecrete() {
  try {
    const { data } = yield call(Api.regenerateSecrete);
    yield put(ActionTypes.regenerateSecrete.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.regenerateSecrete.failure(e));
  }
}

function* regenerateSecreteSaga() {
  yield takeLatest(ActionTypes.REGENERATE_SECRET_KEY, regenerateSecrete);
}

export const profileSaga = function* rootSaga() {
  yield all([
    fork(getUsersEventsSaga),
    fork(toggleShareEventSaga),
    fork(getTagsSaga),
    fork(getUsersByIdSaga),
    fork(putFavoriteSaga),
    fork(getContributorsSaga),
    fork(getProducersSaga),
    fork(patchUpdateUserInfoSaga),
    fork(patchUpdateAdminInfoSaga),
    fork(patchUpdateUserImageSaga),
    fork(getCardHistorySaga),
    fork(postCustomerPortalSaga),
    fork(getOneInvoiceSaga),
    fork(saveNotificationSubscriptionSaga),
    fork(transcribeAudioSaga),
    fork(setAcceptedTermsSaga),
    fork(getEntityPlanSaga),
    fork(updateEntityPlanSaga),
    fork(getMyEntitySaga),
    fork(updateEntitySaga),
    fork(listMyFavoritesSaga),
    fork(regenerateSecreteSaga),
  ]);
};
